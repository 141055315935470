import React, { Component } from 'react';
import pharmbio_logo from './images/pharmbio_logo_new.png';
import uu_logo from './images/uu_logo.png'
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

export default class Header extends Component {
  render() {

    return (

<AppBar color="white" style={{height:"100px"}}>

<Toolbar style={{marginTop: 10}}>

<a style={{display: "flex", float: "left"}}>
        <img src={pharmbio_logo} style={{height: "36px", margin: "auto", marginRight: "10px", maxHeight:"36px"}} />
</a>

      <div >
        <h1 style={{margin: "auto", marginLeft: "10px"}}>
        MMPredGUI
        </h1>
        <h1 style={{paddingLeft:"5px", margin: "auto", marginLeft: "10px", fontSize:"20px", fontWeight:"700"}}>
        - Multiple Molecules Predictive Graphic User Interface
        </h1>

      </div>

      <div style={{ marginLeft: 'auto', marginRight: 12,}}>

      <img src={uu_logo} style={{height: "64px", marginTop: "10", marginBottom: "auto", maxHeight:"64px", float: "right"}} />

    </div>

      </Toolbar>











</AppBar>

    );
  }
}
