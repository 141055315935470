import React, {Component} from 'react';
import * as d3 from "d3";
import axios from '../node_modules/axios';

class PtpChart extends Component {
  constructor(props) {
    super(props);
  };


  componentDidMount() {
    this.drawChart(this.props.ptpData);
  }


    drawChart(data) {
      for (let i=0;i<data.lenght;i++) {
        data[i] = JSON.parse(data[i]);
      }

      let svg = d3.select(`#chart${this.props.index}`).append("svg")
                  .attr("height", "300")
                  .attr("width", "205");

      let enterSelection = svg.selectAll("rect")
                          .data(data)
                          .enter()
      enterSelection.append("rect")
         .attr("class", "upwards")
         .attr("y", 0)
         .attr("width", 0)
         .attr("x", 0)
         .attr("height", 0)
      enterSelection.insert("rect")
         .attr("class", "downwards")
         .attr("y", 0)
         .attr("width", 0)
         .attr("x", 0)
         .attr("height", 0)
      svg.append("line")
          .style("stroke", "black")
          .attr("x1", 0)
          .attr("x2", data.length * 8.2)
          .attr("y1", 299)
          .attr("y2", 299)
      svg.append("line")
          .style("stroke", "black")
          .attr("x1", 0)
          .attr("x2", data.length * 8.2)
          .attr("y1", 1)
          .attr("y2", 1)

      let div = d3.select("body").append("div")
                    .attr("class", "tooltip")
                    .style("opacity", 0);

      svg.selectAll("rect").filter(".upwards")
         .data(data)
         .attr("y", function(d,i) {
             if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                 return (150 - 150 * d['prediction'][0]['pValue']);
             }
             else {
                 return 150;
             }
         })
         .attr("width", 5.5)
         .attr("fill", "#c2a5cf")
         .attr("x", function(d,i) {
             if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                 return (i * 6.5) + 2.5;
             }
             else {
                 return 0;
             }
         })
         .attr("height", function(d,i) {
             if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                 return 150 * d['prediction'][0]['pValue'];
             }
             else {
                 return 0;
             }
         })
         .on("mouseover", function(d) {
             div.transition()
                .duration(200)
                .style("opacity", .9);
             div.html( function() {
                     if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                         return d['modelName'] + "<br>" +
                                "p(" + d['prediction'][0]['label'] + ")=" + d['prediction'][0]['pValue']
                     }
                     else {
                         return "";
                     }
                })
                .style("left", (d3.event.pageX) + 10 + "px")
                .style("top", (d3.event.pageY - 35) + "px")
         })
         .on("mouseout", function(d) {
             div.transition()
                .duration(500)
                .style("opacity", 0);
         })

      svg.selectAll("rect").filter(".downwards")
          .data(data)
          .attr("y", function(d,i) { return 150 })
          .attr("width", 5.5)
          .attr("x", function(d,i) {return (i * 6.5) + 2.5})
          .attr("fill", "#a6dba0")
          .attr("height", function(d,i) {
              if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' )  {
                  return 150 * d['prediction'][1]['pValue'];
              }
              else {
                  return 0;
              }
          })
          .on("mouseover", function(d) {
              div.transition()
                 .duration(200)
                 .style("opacity", .9);
              div.html(function() {
                     if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                         return  d['modelName'] + "<br>" +
                                 "p(" + d['prediction'][1]['label'] + ")=" + d['prediction'][1]['pValue']
                     }
                     else {
                         return "";
                     }
                })
                 .style("left", (d3.event.pageX) + 10 + "px")
                 .style("top", (d3.event.pageY - 35) + "px")

          })
          .on("mouseout", function(d) {
              div.transition()
                 .duration(500)
                  .style("opacity", 0);
          })


        }

        render(){

          let divname = `chart${this.props.index}`;
          return (
            <div id={divname}></div>
          )}
        }
export default PtpChart;
