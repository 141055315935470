import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ProgressiveImage from 'react-progressive-image';
import logo from './loading.gif';

export default class CpLogD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: undefined,
      smiles: undefined,
      confidence: 0.7,
      urlConfidence: 0.7
    };
  }

  // Lifecycle Methods:
  componentWillMount() {
    this.setState({ smiles: this.props.smiles });
    this.setState({ confidence: this.props.confidence });
  }
  componentDidMount() {
    let timeout = 1000;
    setInterval(this.compareConfidence.bind(this), timeout);

    if(window.DEBUG) console.log("Mounting CpLogD");
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ smiles: nextProps.smiles, loading: true });
  }


  // Functions:
  handleConfidenceChange(e) {
    this.setState({ confidence: e.target.value });
  }
  compareConfidence() {
    let confidence = this.state.confidence;
    let urlConfidence = this.state.urlConfidence;

    if ( confidence !== urlConfidence ) {
      this.setState({ urlConfidence: confidence });
      if (window.DEBUG) console.log("New confidence: " + confidence);
    }
  }
  handleImageErrored() {
    this.setState({ loading: "failed to load" });
  }

  // Render:
  render() {
    let visualisationSmiles = "&molecule=" + this.state.smiles.replace("+","%2b");
    let visualisationConfidence = "&confidence=" + this.state.urlConfidence;
    let url = "https://cplogd.serve.scilifelab.se/api/v2/predictImage?imageWidth=500&imageHeight=600&addPrediction=true";
    if (window.DEBUG) console.log("Getting image from " + url + visualisationSmiles + visualisationConfidence);

    let confidence = (
      <div className="box">
        <p className="subtitle has-text-centered conftitle">Confidence</p>
      <label className="pure-material-slider">
        <input
            type="range"
            step="0.01"
            min="0"
            max="1"
            value={this.state.confidence}
            onChange={this.handleConfidenceChange.bind(this)}
          />
      </label>
      <TextField
            id="standard-number"
            type="number"
            value={this.state.confidence}
            onChange={this.handleConfidenceChange.bind(this)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{  min: "0", max: "1", step: "0.01" }}
            margin="normal"
          />
      </div>
    );

    let image = (
      <div className="tile is-child">
        <figure className="image is-3by2" style={{height: "600px", width: "auto", margin: "auto"}}>
          <ProgressiveImage src={url + visualisationSmiles.replace("+","%2b") + visualisationConfidence} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
            {(src, loading) => (
              <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "auto", margin: "auto" }} src={src} alt="molecule"/>
            )}
          </ProgressiveImage>
        </figure>
      </div>
    );

    return (
      <Grid id="show" container spacing={2} style={{width:"auto", height:"auto"}}>
        <Grid item sm={4}>
            <div >{confidence}</div>
        </Grid>
        <Grid item sm={7} style={{width:"auto", height:"auto"}}>
        {image}
      </Grid>
    </Grid>
    )

  }
}
