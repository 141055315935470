import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CheckboxesGroup() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select model:</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name="model" value="MetPred" />}
            label="MetPred"
          />
          <FormControlLabel
            control={<Checkbox  name="model" value="CpLogD" />}
            label="CpLogD"
          />
          <FormControlLabel
            control={
              <Checkbox name="model" value="PTP" />
            }
            label="PTP"
          />
        {/*<FormControlLabel
            control={
              <Checkbox name="model" value="Prosilico" />
            }
            label="Prosilico"
          />*/}
          {/* <FormControlLabel
            control={
              <Checkbox name="model" value="BBBP" />
            }
            label="BBBP"
          /> */}
        </FormGroup>
       </FormControl>
       </div>
     );

   }
