import React from "react";
import { render } from "react-dom";
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ControlledPopup from './ControlledPopup';
import './style.css';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {predModels} from './target.js';
import ReactToPrint from 'react-to-print';
import $ from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

export default class ShowData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      compound: [],
      confidence: 0.7,
      urlConfidence: 0.7,
    };
  }

  //Lifecycle
  componentWillMount(){
    this.windowView();
    predModels.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.makeData(this.props.smiles);
  }

  componentDidMount() {
    let timeout = 1000;
    setInterval(this.compareConfidence.bind(this), timeout);
    //this.stickyHeader();
    this.windowView();
  }


  //Functions
  handleConfidenceChange(e) {
    this.setState({ confidence: e.target.value });
  }
  compareConfidence() {
    let confidence = this.state.confidence;
    let urlConfidence = this.state.urlConfidence;

    if ( confidence !== urlConfidence ) {
      this.setState({ urlConfidence: confidence });

      if (window.DEBUG) console.log("New confidence: " + confidence);
    }
  }

  windowView(){
    $(window).resize(function() {
    $('.ReactTable').height($(window).height() - 150);
    });

    $(window).trigger('resize');
  }

  // getColumnWidth(model){
  //   switch (model){
  //   case "MetPred":
  //     return 220
  //   break;
  //   case "CpLogD":
  //     return 120
  //   break;
  //   case "PTP":
  //    return 250
  //   break;
  //   case "BBBP":
  //     return 120
  //   break;
  //   default:
  //     return 50
  //   break;
  // }
  // }

  async makeData(smiles) {
    if(smiles == ""){
      this.setState({isLoading: false});
    } else {
    let i = 0;
    for (let smile of smiles){
      let data = await axios.get("/chemidconvert?predictionType=molWeight&smiles="+smile).then(response => {if (response.data.molWeight===null || response.data.molWeight==undefined){return "Unknown"}
      else {return response.data.molWeight.toFixed(3)}});

      ///chemidconvert?predictionType=molWeight&smiles=
      let name = await axios.get("/chemidconvert?predictionType=names&smiles="+smile).then(response =>
        {if (response.data.names===null || response.data.names==undefined){return "Unknown"}
        else {return response.data.names[0]}
      });

      ///chemidconvert?predictionType=names&smiles=
      let img = ('https://chemid.serve.scilifelab.se/v1/asSvg?smiles='+smile.replace("+","%2b")+'&width=190&height=190');

      let url;
      let bbbpData = "";
      let ptpData = [];

      if(this.props.models.includes("BBBP")){
      url = `/bbbp?smiles=`+smile;
      bbbpData = await axios.get(url).then(response => {return response.data});
      }
      if(this.props.models.includes("PTP")){
      url = "";
      let urlProfiles = predModels.map((predModel) =>
      url = "/ptp?predictionType="+predModel.host+"/v2/predict&smiles="+smile)
      let j = 0;
      for (let urlProfile of urlProfiles){
        ptpData[j] = await axios.get(urlProfile).then(response => {return response.data});
        //console.log(i,ptpData[j])
        j++;
      }
      }
      this.setState({ compound: [...this.state.compound, ...[{
      id: i+1,
      data:{
      smile: smile,
      images: img,
      dataTxt: data,
      names: name,
      ptpData: ptpData,
      bbbpData: bbbpData,
      },
      }] ] });

      console.log(i);

      if(smiles.length >= 10 && i >= 9){
        this.setState({isLoading: false})
      } else if(smiles.length == (i+1)){
        this.setState({isLoading: false})
      }

      i++;

      }

        //this.setState({isLoading: false})

      }
    }

print(){
window.print()
}

  render() {

    console.log(this.props.smiles.length);

    const CUSTOM_COLUMNS = (this.props.models.map((model, index) =>
                            [{
                              Header: model,
                              accessor: model,

                              style: {
                                textAlign: "center",
                              },
                              Cell: row => {
                              return (
                                <div>
                                  <ControlledPopup model={model}
                                     metpredData={row.row.data.metpredData}
                                     ptpData={row.row.data.ptpData}
                                     bbbpData={row.row.data.bbbpData}
                                     smile={row.row.data.smile}
                                     index={row.row.id}
                                     confidence={this.state.confidence}/>
                                </div>
                              )
                            }
                          }][0]
                          ));

    let confidence = (
      <div className="box">
        <p className="subtitle has-text-centered conftitle"   style={{margin: "0px"}}>Confidence
          <TextField
                style={{verticalAlign: "0px", paddingLeft: "10px"}}
                id="standard-number"
                type="number"
                value={this.state.confidence}
                onChange={this.handleConfidenceChange.bind(this)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{  min: "0", max: "1", step: "0.01" }}
                margin="normal"
              />
        </p>
      <label className="pure-material-slider">
        <input
            style={{verticalAlign: "0px", paddingBottom: "7px", marginLeft: "-10px"}}
            type="range"
            step="0.01"
            min="0"
            max="1"
            value={this.state.confidence}
            onChange={this.handleConfidenceChange.bind(this)}
          />
      </label>

      </div> )

    const { compound } = this.state;

    console.log(this.state.compound)
    return (

      <Paper className="root rounded elevation2">
      <Table aria-label="simple table" id="one" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" align="left" style={{textAlign: "center", width: "45%"}}>
                <Link to="/" style={{textDecoration: 'none'}}>
                <Button variant="contained" onClick={this.props.handler("new")}>New Prediction</Button>
                </Link>
                {/*<Link to={{ pathname: "/sort", state: { compound: this.state.compound} }} style={{textDecoration: 'none'}}>
                <Button variant="contained" color="secondary" style={{marginLeft: "10px"}}>Sort By</Button>
                </Link>*/}
                <ReactToPrint
                  trigger={() => <Button variant="contained" color="primary" style={{marginLeft: "10px"}}>Print Page</Button>}
                  content={() => this.componentRef}
                />
                
              </TableCell>
              <TableCell component="th" scope="row" align="left" style={{textAlign: "center", padding: "0px"}}>
                {confidence}
              </TableCell>
            </TableRow>
            </TableHead>
            </Table>
            {this.state.isLoading ? <div style={{position:"absolute", top:"50%", left:"50%", right:"50%", margin:"0 auto", textAlign:"center"}}><CircularProgress style={{width:"80px", height:"80px"}}/></div> : (
        <ReactTable
          id="printTable"
          ref={el => (this.componentRef = el)}
          data={compound}
          columns={[

                    {
                      Header: "N",
                      accessor: "id",
                      width: 40
                    },
                    {
                      Header: "Compound",
                      accessor: "data",
                      Cell: row => {
                      return (
                        <div>
                          <p className="class-for-name wrap" style={{marginTop: 0,wordBreak: "break-all",whiteSpace: "normal"}}>Name: {row.row.data.names}</p>
                          <p style={{wordBreak: "break-all",whiteSpace: "normal" }}className="class-for-description wrap">Smiles: {row.row.data.smile}</p>
                          <p style={{wordBreak: "break-all",whiteSpace: "normal" }}className="class-for-weight">Mol weight: {row.row.data.dataTxt}</p>
                          <span style={{display: "block" }}>
                          <img src={row.row.data.images}></img>
                          </span>
                        </div>
                      )
                    }
                    },
                    ...CUSTOM_COLUMNS
              ]}
          defaultPageSize={10}
          // style={{
          //   height: "800px"
          // }}
          className="-striped -highlight page-break"
        />)}
      </Paper>
  )
  }
}
