import React, {Component} from 'react';
import Popup from "reactjs-popup";
import axios from 'axios';
import './style.css';
import Typography from '@material-ui/core/Typography';
import PtpChart from './PtpChart';
import Ptp from './Ptp';
import './style.css';
import TextField from '@material-ui/core/TextField';
import ProgressiveImage from 'react-progressive-image';
import logo from './loading.gif';
import CpLogD from './CplogD';

class ControlledPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false,
                   cplogdData: "",
                   metpredData: "",
                   smiles: this.props.smile.replace("+","%2b")}
                   ;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }
  //Lifecycle
  componentDidMount() {
    // const script = document.createElement("script");

    // script.innerHTML = 'window.initRDKitModule().then(function(RDKit) {console.log("RDKit version: " + RDKit.version());window.RDKit = RDKit;'
    // script.src = "rdkit.js"
    // document.body.appendChild(script);
    this.getPredictionStats(this.props.smile.replace("+","%2b"), this.props.confidence);
    console.log("PROPS:",this.state.smiles)
    // var mol = RDKit.get_mol(this.state.smiles);
    // console.log(mol)
    axios.get("https://metpredapi.serve.scilifelab.se/api/v2/predictImage?molecule=" + this.state.smiles)
    .then(response => {this.setState({smiles: response.data})}).then(() => {console.log(this.state.smiles)});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.confidence !== this.props.confidence) {
    this.getPredictionStats(this.props.smile.replace("+","%2b"), nextProps.confidence);
  }
  }
  smilesToMol() {
    //console.log(this.props.smile)
    // var url = "localhost:5000/convert?compound="
    // var txt = axios.get(url + this.props.smile).then(response => {return response.data})
    // this.props.smile = txt
    return this.state.smiles.replace("+","%2b")
  }

  //Functions
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  async getPredictionStats(smile, confidence) {
    let url;
    let txt;
    url = `/cplogd?smiles=`+smile.replace("+","%2b")+`&confidence=${this.props.confidence}`;
    txt = await axios.get(url).then(response => {return response.data});
    this.setState({cplogdData : txt})
    url = `/metpred?smiles=`+smile;
    txt = await axios.get(url).then(response => {return response.data});
    console.log("HEREEEEE: ",txt)
    this.setState({metpredData : txt})
  }

  bbbpDataPrev(data) {
    for (let i = 0; i < data.length; i++) {
         return (
           <div>
          <p>P(np): {data[i].probability}</p>
          <p>P(p): {data[i+1].probability}</p>
            </div>
       )
       }
     }

     handleImageErrored() {
       this.setState({ loading: "failed to load" });
     }

  render() {
    //console.log(this.state.bbbptxt)

    return (
      <div>
        {this.props.model === "MetPred" &&
        <button data-toggle="tooltip" data-placement="top" title="Click to expand" className="button" onClick={this.openModal} style={{border:"none", width:"205px", height:"200px", background:"transparent"}}>
          <img src={`https://metpredapi.serve.scilifelab.se/api/v2/predictImage?molecule=${(this.props.smile).replace("+","%2b")}`} style={{width:"195px", height:"190px"}}/>
        </button>
        }
        {this.props.model === "CpLogD" &&
        <button data-toggle="tooltip" data-placement="top" title="Click to expand" className="button" onClick={this.openModal} style={{border:"none", width:"auto", height:"200px", background:"transparent"}}>
          <Typography variant="h6">
            {this.state.cplogdData.predictionMidpoint}
          </Typography>
            <Typography>{
                  "\n("+this.state.cplogdData.lower+"; "+this.state.cplogdData.upper+")"
                  }
            </Typography>
        </button>
        }
        {this.props.model === "PTP" &&
        <button data-toggle="tooltip" data-placement="top" title="Click to expand" className="button btnChart" onClick={this.openModal} >
            <PtpChart ptpData={this.props.ptpData} index={this.props.index}/>
        </button>
        }
        {this.props.model === "Prosilico" &&
        <button className="button" onClick={this.openModal} style={{border:"none"}}>
          Model Details
        </button>
        }
        {this.props.model === "BBBP" &&
        <button className="button" onClick={this.openModal} style={{border:"none", width:"auto", height:"200px", maxWidth: "100px", background:"white"}}>
            <Typography>
              {this.bbbpDataPrev(this.props.bbbpData)}
            </Typography>
        </button>
        }

        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className="modal">
            <div style={{textAlign:"center"}}>
            <h1>{this.props.model}</h1>
            {this.props.model === "MetPred" &&
            <div>
            <img src={`https://metpredapi.serve.scilifelab.se/api/v2/predictImage?molecule=${(this.props.smile).replace("+","%2b")}`} style={{height:"600px",width:"300px", paddingRight:"30px"}}/>
            <textarea value={this.state.metpredData} style={{height:"600px",width:"300px"}}></textarea>
            </div>
            }
            {this.props.model === "CpLogD" &&
                <div>
                  <CpLogD smiles={this.props.smile.replace("+","%2b")} confidence={this.props.confidence}/>
                </div>
              }
              {this.props.model === "PTP" &&
                <div>
                  <h3 style={{margin: 0}}>Predictive Target Profile</h3>
                  <Ptp smiles={this.props.smile.replace("+","%2b")} ptpData={this.props.ptpData} confidence={this.props.confidence}/>
                </div>
                }
            {this.props.model === "BBBP" &&
                <figure style={{height: "600px", width: "auto", margin: "auto"}}>
                  <ProgressiveImage src={`http://blood-brain-barrier-penetration-cvap-cpsign.app.os2.pharmb.io/v1/predictImage?molecule=${this.props.smile}&imageWidth=600&imageHeight=600&addProbability=true&addTitle=true`} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
                    {(src, loading) => (
                      <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "auto", margin: "auto"}} src={src} alt="molecule"/>
                    )}
                  </ProgressiveImage>
              </figure>
              }

            <div>

            </div>
            <a className="close" onClick={this.closeModal}>
              &times;
            </a>
          </div>
        </div>
        </Popup>
      </div>
    );
  }
}

export default ControlledPopup;
