import MultiText from './MultiTextField'
import Models from './Models'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React, { Component } from "react";
import { render } from "react-dom";
import jQuery from "jquery";
import Grid from '@material-ui/core/Grid';
import Uploader from './UploadFile.js'
import ShowData from './ShowData'
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Sort from './Sort.js';
import Others from './Other'
import Header from './Header.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

let uploadData = [];

class App extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.getText = this.getText.bind(this);
    this.state = {
      screen: "home",
      smiles: [],
      models: [],
      show: false,
    }
  }

  //Lifecycle
  componentDidMount(){
    }

  componentWillMount(){
      jQuery('#myTextBox').val();
  }
  mapRow(row) {
    return row.replace("+","%2b");
  }
  //Function
  getText() {

    const splitLines = str => str.split(/\r?\n/);
    let textData = [];

    if (uploadData.length === 0){
      this.setState({smiles: splitLines(jQuery('#myTextBox').val())});
    } else if (uploadData.length > 0 && jQuery('#myTextBox').val() !== ""){

      textData = splitLines(jQuery('#myTextBox').val());

      let primes = uploadData.concat(textData);
      this.setState({smiles: primes});
    } else {
    this.setState({smiles: uploadData});
    }

    if (uploadData.length > 0 && jQuery('#myTextBox').val() !== ""){

      textData = splitLines(jQuery('#myTextBox').val());

      let primes = uploadData.concat(textData);
      this.setState({smiles: primes.map(this.mapRow)});
    }

    let mods = [];
    jQuery.each(jQuery("input[name='model']:checked"), function(){
    mods.push(jQuery(this).val());
    });
    this.setState({models:mods});

  }

  handler(data) {
    if (data==="" || data==="new"){
      uploadData = [];
    } else {
    const splitLines = str => str.split(/\r?\n/);
    uploadData = splitLines(data);
        }
      }

  render() {

    console.log(this.state.smiles)
    console.log(this.state.models)


    return (

      <div id="baseContent" style={{paddingLeft:"5%", paddingRight:"5%", paddingTop: "20px"}}>
        <Router>
          <Switch>

        <Route exact path="/">
          <Header/>
        <Box borderRadius="1%" className="no-print" borderColor="primary.main" border={1} style={{marginTop: "130px", padding:"20px", textAlign:"center"}}>
          <h4>Upload File (only text file) or Type Molecules Smiles on the text field, select your Models (one or more) and push the Button (GO!)</h4>
          <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                item>
            <div>
            <Uploader handler = {this.handler}/>
            <MultiText />
            {/*</div>
            <div style={{display: 'flex'}}>
              <FormControl component="fieldset" style={{marginLeft: "10px"}}>
                <FormLabel component="legend">Select model:</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="model" value="MetPred" onChange={this.getText}/>}
                    label="MetPred"
                  />
                  <FormControlLabel
                    control={<Checkbox  name="model" value="CpLogD" onChange={this.getText}/>}
                    label="CpLogD"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="model" value="PTP" onChange={this.getText}/>
                    }
                    label="PTP"
                  />
                {/*<FormControlLabel
                    control={
                      <Checkbox name="model" value="Prosilico" />
                    }
                    label="Prosilico"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="model" value="BBBP" onChange={this.getText}/>
                    }
                    label="BBBP"
                  />
                </FormGroup>
               </FormControl>
               </div>*/}
             </div>
            <Models getText = {this.getText}/>
              {/* <Link to="/others" style={{fontSize:12}}>
                Others>>
              </Link> */}
          </Grid>
          <Grid container justify="center"
            alignItems="center">
          <Grid>
            <Link to="/results" style={{textDecoration: 'none'}}>
            <Button variant="contained" color="secondary" onClick={this.getText.bind(this)}>
              Go!
            </Button>
            </Link>
          </Grid>
        </Grid>
    </Box>
    </Route>
   <Route path="/results">
   <Box>
      <ShowData smiles={this.state.smiles} models={this.state.models} showHome={this.showHome} handler={this.handler}/>
  </Box>
  </Route>
    <Route path="/sort" render={(props) => <Sort {...props} smiles={this.state.smiles} models={this.state.models}/>}>
  </Route>
  <Route path="/others">
    <Others />
  </Route>
      </Switch>
      </Router>
  </div>
)}

}

export default App;
