import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import linedtextarea from './JQueryLinedText-master/jquery-linedtextarea';
import './JQueryLinedText-master/jquery-linedtextarea.css';
import jQuery from "jquery";

class MultiText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smiles: "",
      models: [],
    }
  }

  //Lifecycle
  componentDidMount(){
    this.linedtextarea();
  }

  //Function
  linedtextarea() {
    jQuery(function() {
        jQuery(".lined").linedtextarea(
        {selectedLine: 1})
        jQuery("#myTextBox").linedtextarea();
    });
  }

  handleChange = (e) => {
        this.setState({smiles: jQuery('#myTextBox').val()})
        console.log("changed")
  }


  render() {
  return (
    <div >
    <FormControl component="fieldset" >
      <FormLabel component="legend"></FormLabel>
          <TextField
            id="myTextBox"
            multiline
            rows="9"
            defaultValue=""
            margin="normal"
            variant="outlined"
            style={{width:"500px"}}
            onChange={this.handleChange}
          />
      </FormControl>
    </div>
    )
}
}
export default MultiText;
