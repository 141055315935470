import React, {Component} from 'react';
import jQuery from "jquery";
import Button from '@material-ui/core/Button';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

let fileReader;

class Uploader extends Component{

handleFileRead = (e) => {
  const content = fileReader.result;
  const splitLines = str => str.split(/\r?\n/);
  this.props.handler(content);
  toast.success(<div>
    <h3>
      {splitLines(content).length} lines have been uploaded
    </h3>
  </div>)
};

handleFileChosen = (file) => {
  if(!file){
} else {
  fileReader = new FileReader();
  fileReader.onloadend = this.handleFileRead;
  fileReader.readAsText(file);
}
};

render() {

  jQuery(document).ready(function() {
   jQuery('#btn-file-reset').on('click', function() {
      jQuery('#file').val('');
   });
});

return (
<div className='upload-expense' style={{textAlign:"left"}}>
  <ToastContainer closeOnClick={false} position="top-right"
    position="top-right"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover/>
  <label htmlFor="outlined-button-file">
        <Button variant="outlined" component="span" >
          <input type='file'
                id='file'
                className='input-file'
                accept='.txt'
                onChange= {e => this.handleFileChosen(e.target.files[0])}/>
                <IconButton>
                <button id="btn-file-reset" type="button" style={{border:"none"}} onClick={e => this.props.handler("")}><RemoveCircleOutlineRoundedIcon fontSize="small" /></button>
                </IconButton>
        </Button>
      </label>
</div>
    )
  }
}

export default Uploader;
