import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Carousel from './Carousel.js'

export default class Sort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sel: "",
    };
  }

  handleChange(e){
        this.setState({sel: e});
        //console.log(e);
  };

  render(){

// console.log(this.state.sel);
// console.log(this.props.smiles);
// console.log(this.props.models);
console.log(this.props.location.state);

    return(
      <Paper className="root rounded elevation2">
      <FormControl component="fieldset">
        <FormLabel component="legend">Sort By</FormLabel>
        <RadioGroup aria-label="SortBy" name="SortBy" onChange={e => this.handleChange(e.target.value)}>
          <FormControlLabel value="Substance" control={<Radio value={"substance"}/>} label="Substance" />
          <FormControlLabel value="Model" control={<Radio value={"model"}/>} label="Model" />
        </RadioGroup>
    </FormControl>
    {this.state.sel === "substance" &&
    <Card style={{backgroundColor: "#bfbfbf9c"}}>
      <CardActionArea>
      <h1 style={{paddingLeft: "10px"}}>Sorting by Substance</h1>
      <Carousel smiles={this.props.smiles} sel={this.state.sel}/>
      </CardActionArea>
    </Card>
    }
    {this.state.sel === "model" &&
    <Card style={{backgroundColor: "#bfbfbf9c"}}>
      <CardActionArea>
      <h1 style={{paddingLeft: "10px"}}>Sorting by Model</h1>
      <Carousel models={this.props.models} sel={this.state.sel}/>
      </CardActionArea>
    </Card>
    }
    </Paper>
  )
  }

}
