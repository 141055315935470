import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";

export default class Carousel extends Component {
  static get CONTAINER_STYLE() {
    return {
      position: "relative",
      height: "650px",
      width: "100%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "middle"
    };
  }

  static get CARD_STYLE() {
    return {
      height: "600px",
      width: "500px",
      paddingTop: "80px",
      textAlign: "center",
      background: "white",
      color: "black",
      border: "1px",
      fontFamily: "sans-serif",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "10px",
      boxSizing: "border-box"
    };
  }

  render() {
    return (

      <div style={Carousel.CONTAINER_STYLE}>
        {this.props.sel === "substance"  &&
            <ReactCardCarousel>
          {this.props.smiles.map((smile, index) =>
            <div style={Carousel.CARD_STYLE}><h4>{index+1}. {smile}</h4>
            </div>
          )}
          </ReactCardCarousel>
        }
          {this.props.sel === "model"  &&
          <ReactCardCarousel>
            {this.props.models.map((model) =>
              <div style={Carousel.CARD_STYLE}><h4>{model}</h4></div>
            )}
            </ReactCardCarousel>
          }

      </div>

    );
  }
}
