import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Carousel from './Carousel.js';
import axios from 'axios';

export default class Sort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherModels: [],
      list: [],
      sel: "",
    }
  };

  // Lifecycle Methods:
  componentDidMount(){
    this.getModelsList();
    }

  componentWillReceiveProps(nextProps) {
    if(nextProps.sel !== this.state.sel){
      this.setState({list: this.showListApi()})
    }
  }

  async getModelsList(){
        let modelsList = await axios.get("/api/getModelsList").then(response => {return response.data});
        this.setState({otherModels : modelsList})
        console.log(modelsList)
  }

  handleChange(e){
        this.showListApi(e);
        this.setState({sel: e});
        //console.log(e);
  };

  showListApi(e){
    let res = this.state.otherModels.filter((model) => {
      return model.ServiceName == e;
    }).
    map((model) => {
      return model.Result[1].ResultValues.map((value)=>
    {return (<li>{value[2]}</li>)})
    })
    //console.log(res)
    return this.state.list = res
  }


  render(){
    console.log(this.state.sel)
    console.log(this.state.list)
    //console.log(this.showListApi())

    return(

        <Paper className="root rounded elevation2">
        <FormControl component="fieldset">
          <FormLabel component="legend">List of Other API Service</FormLabel>
          <RadioGroup aria-label="Others" name="Others" onChange={e => this.handleChange(e.target.value)}>
          {this.state.otherModels.map((service)=>
            <FormControlLabel value={service.ServiceName} control={<Radio value={service.ServiceName} />} label={service.ServiceName} />)}
          </RadioGroup>
      </FormControl>
        <div>
      {this.state.list.map(l=>
      <p>{l}</p>)}
        </div>
      </Paper>


  )
  }

}
