export const predModels = [
  {
    "name": "ACHE",
    "host": "ptp.serve.scilifelab.se/ache/api"
  },
  {
    "name": "CHRM3",
    "host": "ptp.serve.scilifelab.se/chrm3/api"
  },
  {
    "name": "CNR1",
    "host": "ptp.serve.scilifelab.se/cnr1/api"
  },
  {
    "name": "CNR2",
    "host": "ptp.serve.scilifelab.se/cnr2/api"
  },
  {
    "name": "DRD1",
    "host": "ptp.serve.scilifelab.se/drd1/api"
  },
  {
    "name": "DRD2",
    "host": "ptp.serve.scilifelab.se/drd2/api"
  },
  {
    "name": "EDNRA",
    "host": "ptp.serve.scilifelab.se/ednra/api"
  },
  {
    "name": "HTR1A",
    "host": "ptp.serve.scilifelab.se/htr1a/api"
  },
  {
    "name": "HTR2A",
    "host": "ptp.serve.scilifelab.se/htr2a/api"
  },
  {
    "name": "KCNH2",
    "host": "ptp.serve.scilifelab.se/kcnh2/api"
  },
  {
    "name": "LCK",
    "host": "ptp.serve.scilifelab.se/lck/api"
  },
  {
    "name": "ADORA2A",
    "host": "ptp.serve.scilifelab.se/adora2a/api"
  },
  {
    "name": "MAOA",
    "host": "ptp.serve.scilifelab.se/maoa/api"
  },
  {
    "name": "NR3C1",
    "host": "ptp.serve.scilifelab.se/nr3c1/api"
  },
  {
    "name": "OPRD1",
    "host": "ptp.serve.scilifelab.se/oprd1/api"
  },
  {
    "name": "OPRK1",
    "host": "ptp.serve.scilifelab.se/oprk1/api"
  },
  {
    "name": "OPRM1",
    "host": "ptp.serve.scilifelab.se/oprm1/api"
  },
  {
    "name": "PDE3A",
    "host": "ptp.serve.scilifelab.se/pde3a/api"
  },
  {
    "name": "PTGS1",
    "host": "ptp.serve.scilifelab.se/ptgs1/api"
  },
  {
    "name": "PTGS2",
    "host": "ptp.serve.scilifelab.se/ptgs2/api"
  },
  {
    "name": "SCN5A",
    "host": "ptp.serve.scilifelab.se/scn5a/api"
  },
  {
    "name": "SLC6A2",
    "host": "ptp.serve.scilifelab.se/slc6a2/api"
  },
  {
    "name": "ADRB1",
    "host": "ptp.serve.scilifelab.se/adrb1/api"
  },
  {
    "name": "SLC6A3",
    "host": "ptp.serve.scilifelab.se/slc6a3/api"
  },
  {
    "name": "SLC6A4",
    "host": "ptp.serve.scilifelab.se/slc6a4/api"
  },
  {
    "name": "ADRB2",
    "host": "ptp.serve.scilifelab.se/adrb2/api"
  },
  {
    "name": "AR",
    "host": "ptp.serve.scilifelab.se/ar/api"
  },
  {
    "name": "AVPR1A",
    "host": "ptp.serve.scilifelab.se/avpr1a/api"
  },
  {
    "name": "CCKAR",
    "host": "ptp.serve.scilifelab.se/cckar/api"
  },
  {
    "name": "CHRM1",
    "host": "ptp.serve.scilifelab.se/chrm1/api"
  },
  {
    "name": "CHRM2",
    "host": "ptp.serve.scilifelab.se/chrm2/api"
  }
];
