import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import logo from './loading.gif';
import { Table, Thead, Tbody, Tr, Th, Td } from '../node_modules/react-super-responsive-table';
import {predModels} from './target.js'
import '../node_modules/react-super-responsive-table';
import axios from '../node_modules/axios';
import * as d3 from "d3";
import './style.css';
import cross from './images/cross.svg';
import purplegreen from './images/purplegreen.svg';
import $ from "jquery";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

let svg;

export default class Ptp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selProfile: undefined,
      urlProfile: undefined,
      loading: false,
      smiles: undefined,
      confidence: 0.7,
      urlConfidence: 0.7
    }
  };

  // Lifecycle Methods:
  componentWillMount() {
    this.setState({ smiles: this.props.smiles });
        this.setState({ confidence: this.props.confidence });
    predModels.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }
  componentDidMount() {
    this.displayComponent();
    let timeout = 1000;
    setInterval(this.compareConfidence.bind(this), timeout);
    this.drawChart(this.props.ptpData);
    this.drawButtons(this.props.ptpData);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.smiles !== this.state.smiles) {
        this.setState({ smiles: nextProps.smiles, loading: true });
        this.drawUpdate();

          this.setState({urlProfile: undefined});
          this.setState({confidence: 0.7, urlConfidence: 0.7});

      }
  }

// Functions

    handleConfidenceChange(e) {
      this.setState({ confidence: e.target.value });
    }
    compareConfidence() {
      let confidence = this.state.confidence;
      let urlConfidence = this.state.urlConfidence;

      if ( confidence !== urlConfidence ) {
        this.setState({ urlConfidence: confidence });
        this.drawButtons(this.props.ptpData);
        if (window.DEBUG) console.log("New confidence: " + confidence);
      }
    }
    handleImageErrored() {
      this.setState({ loading: "failed to load" });
    }


// Draw Functions
drawUpdate() {
  if (svg !== undefined)
    svg.remove();

  d3.select(".target-list")
    .selectAll("button")
    .style("background-color", "")
    .style("background-image", "")

}

rectOnClick(name) {
  console.log(name);
  let urlModel = predModels.filter(obj => {return obj.name == name})
  console.log(urlModel[0].host);
  this.setState({ selProfile: name, urlProfile: urlModel[0].host})
}

drawChart(data) {
  for (let i=0;i<data.lenght;i++) {
    data[i] = JSON.parse(data[i]);
  }



  svg = d3.select(".chart1").append("svg")
              .attr("height", "300")
              .attr("width", "260");

  let enterSelection = svg.selectAll("rect")
                      .data(data)
                      .enter()
  enterSelection.append("rect")
     .attr("class", "upwards")
     .attr("y", 0)
     .attr("width", 0)
     .attr("x", 0)
     .attr("height", 0)
  enterSelection.insert("rect")
     .attr("class", "downwards")
     .attr("y", 0)
     .attr("width", 0)
     .attr("x", 0)
     .attr("height", 0)
  svg.append("line")
      .style("stroke", "black")
      .attr("x1", 0)
      .attr("x2", data.length * 8.2)
      .attr("y1", 299)
      .attr("y2", 299)
  svg.append("line")
      .style("stroke", "black")
      .attr("x1", 0)
      .attr("x2", data.length * 8.2)
      .attr("y1", 1)
      .attr("y2", 1)

  let div = d3.select("body").append("div")
                .attr("class", "tooltip1")
                .style("opacity", 0)
                .style("z-index", 9999);

  svg.selectAll("rect").filter(".upwards")
     .data(data)
     .attr("y", function(d,i) {
         if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
             return (150 - 150 * d['prediction'][0]['pValue']);
         }
         else {
             return 150;
         }
     })
     .attr("width", 7)
     .attr("fill", "#c2a5cf")
     .attr("x", function(d,i) {
         if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
             return (i * 8) + 4;
         }
         else {
             return 0;
         }
     })
     .attr("height", function(d,i) {
         if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
             return 150 * d['prediction'][0]['pValue'];
         }
         else {
             return 0;
         }
     })
     .on("mouseover", function(d) {
         div.transition()
            .duration(200)
            .style("opacity", .9);
         div.html( function() {
                 if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                     return d['modelName'] + "<br>" +
                            "p(" + d['prediction'][0]['label'] + ")=" + d['prediction'][0]['pValue']
                 }
                 else {
                     return "";
                 }
            })
            .style("left", (d3.event.pageX) + 10 + "px")
            .style("top", (d3.event.pageY - 35) + "px")
     })
     .on("mouseout", function(d) {
         div.transition()
            .duration(500)
            .style("opacity", 0);
     })
     .on("click",d => {
      this.rectOnClick(d['modelName'])
       });


  svg.selectAll("rect").filter(".downwards")
      .data(data)
      .attr("y", function(d,i) { return 150 })
      .attr("width", 7)
      .attr("x", function(d,i) {return (i * 8) + 4})
      .attr("fill", "#a6dba0")
      .attr("height", function(d,i) {
          if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' )  {
              return 150 * d['prediction'][1]['pValue'];
          }
          else {
              return 0;
          }
      })
      .on("mouseover", function(d) {
          div.transition()
             .duration(200)
             .style("opacity", .9);
          div.html(function() {
                 if ( typeof d !== 'undefined' && typeof d['prediction'] !== 'undefined' ) {
                     return  d['modelName'] + "<br>" +
                             "p(" + d['prediction'][1]['label'] + ")=" + d['prediction'][1]['pValue']
                 }
                 else {
                     return "";
                 }
            })
             .style("left", (d3.event.pageX) + 10 + "px")
             .style("top", (d3.event.pageY - 35) + "px")

      })
      .on("mouseout", function(d) {
          div.transition()
             .duration(500)
              .style("opacity", 0);
      })
      .on("click", d => {
          this.rectOnClick(d['modelName'])
        });
      }


  drawButtons(data){

  let threshold = this.state.confidence;

    for (let d of data){
    d3.select(".target-list")
        .selectAll("#"+d["modelName"])
        .style("background-color", function(){
          if (d['prediction'][0]['pValue'] > (1 - threshold) &&
              d['prediction'][1]['pValue'] < (1 - threshold)) {
              return "#c2a5cf"
          }
         if (d['prediction'][1]['pValue'] > (1 - threshold) &&
              d['prediction'][0]['pValue'] < (1 - threshold)) {
              return "#a6dba0"
          }
        if (d['prediction'][0]['pValue'] > 0 && d['prediction'][1]['pValue'] === 0){
          return "#c2a5cf"
        }
        if (d['prediction'][1]['pValue'] > 0 && d['prediction'][0]['pValue'] === 0){
          return "#a6dba0"
        }
        })
        .style("background-image", function(){
          if (d==="error") {
              return `url(${cross})`
          }
          if (d!="" && d!="error") {
              if(d['prediction'][0]['pValue'] > threshold &&
                 d['prediction'][1]['pValue'] > threshold) {
                  return `url(${purplegreen})`
              }
          }
        })
      }
    }

    // Responsive optimize function
    displayComponent(){
          if ($(window).width() >= 769 && $(window).width() <= 1216) {
              $('#c').remove().insertAfter($('#z'));
          }
          else {
            $('#c').remove().insertAfter($('#b'));
          };
      }



    render(){

      let smiles = "?molecule=" + this.state.smiles + "&imageWidth=400&imageHeight=400&addPvals=true&addTitle=true&addPrediction=true";
      let url = "http://" + this.state.urlProfile + "/v2/predictImage";

      if (this.state.urlProfile === undefined){
        url = "https://ptp.serve.scilifelab.se/ache/api/v2/predictImage?molecule"
        smiles = "";
      }

      let image = (
        <figure style={{height: "460px", width: "330px", margin: "auto"}}>
          <ProgressiveImage src={url + smiles} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
            {(src, loading) => (
              <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "330px", margin: "auto"}} src={src} alt="molecule"/>
            )}
          </ProgressiveImage>
      </figure>);


      let confidence = (
        <div className="box">
          <p className="subtitle has-text-centered conftitle">Confidence</p>
        <label class="pure-material-slider">
          <input
              type="range"
              step="0.01"
              min="0"
              max="1"
              value={this.state.confidence}
              onChange={this.handleConfidenceChange.bind(this)}
            />
        </label>
        <TextField
              id="standard-number"
              type="number"
              value={this.state.confidence}
              onChange={this.handleConfidenceChange.bind(this)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{  min: "0", max: "1", step: "0.01" }}
              margin="normal"
            />
        </div>
      );

      // Controll logs
      // console.log(this.state.urlProfile);
      // console.log(this.state.selProfile);
      // console.log(this.state.smiles);
      // console.log(this.state.confidence)


  return (

  <Grid id="show" container spacing={2} style={{width:"auto", height:"auto"}}>
        <Grid item sm={5}>
            <div >{confidence}</div>
            <div className="chart1"></div>
        </Grid>
        <Grid item sm={7}>
              <div style={{width:"auto"}}>{image}</div>
        </Grid>
          <Grid item sm={12}>
                  <Paper >
                    <Table>
                      <Thead>
                          <Th><p className="subtitle has-text-centered" style={{paddingBottom:"15px", margin:0}}>Select target</p></Th>
                      </Thead>
                      <Tbody>
                      <Tr></Tr>
                        <Tr>
                          <Td>
                          <div className="target-list">
                            <div className ="buttons are-small" id="table-column">{predModels.map(predModels=>
                              <button style={{width: "75px", margin:"5px", textAlign:"center", background:"white", backgroundSize: ""}} key={predModels.name} id={predModels.name} className="button" onClick={() => this.setState({ selProfile: predModels.name, urlProfile: predModels.host })}>{predModels.name}</button>)}
                            </div>
                          </div>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Paper>
          </Grid>
    </Grid>

  )
}}
